import { CS } from "../../../base/utils/styler";

export const baseStyle: CS = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    gap: '20px'
}

export const buttonStyle: CS = {
    height: '44px',
}   