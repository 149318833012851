import { Div } from "../../../../../base/components/native/div"
import state from "../../../../../base/services/state"
import helpers from "../../../../../helpers"
import locale from "../../../../../helpers/locale"
import { ActionBack } from "../../../../shared/action-back"
import { ActionButton } from "../../../../shared/action-button"
import { PriceInput } from "../../../../shared/price-input"
import { WizardSlide } from "../../../../shared/slide/slide"

export const FeesSlide = () => {

    const base = WizardSlide()
    const cost = PriceInput('دستمزد دریافتی (اختیاری)', '0')
    const next = ActionButton('ذخیره')
    const prev = ActionBack(helpers.strings.PREV_STEP)
    const keys = Div()
    keys.append(next, prev)
    base.append(cost, keys)

    next.on('click', () => { // todo: move logic to the input component
        let fee = locale.replacePersianDigits(cost.getValue())
        if (isNaN(parseInt(fee))) fee = '0'
        state.merge('new-appointment', { fees: parseInt(fee) })
        base.emit('done')
    })
    prev.on('click', () => base.emit('prev'))
    base.on('enter', () => setTimeout(() => cost.focus(), 450))
    base.on('reset', () => cost.clear())

    keys.cssClass({
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-between',
        marginTop: '40px',
        gap: '16px',
    })
    base.cssClass({
        padding: '15px 60px',
    })

    return base
}