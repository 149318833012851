
import { Div } from "../../../../../../base/components/native/div"
import { ITransactionDay } from "../../../../../../interfaces/transactions"
import { Price } from "../../../../../shared/price"
import { TransactionItem } from "./item"

export const TransactionDay = (d: ITransactionDay) => {
    const base = Div()
    base.cssClass({
        borderBottom: '1px solid #eee',
        // position: 'sticky',
        // top: '0',

    })
    // Row
    const row = Div()
    row.cssClass({
        display: 'grid',
        gridTemplateColumns: '98px 1fr',
        gap: '14px',
        backgroundColor: '#fff',
        // top: '107px',
        // position: 'sticky',
        // zIndex: '2',
    })
    base.append(row)

    const title = Div(d.wd + ' <b>' + d.day + '</b>')
    title.cssClass({
        fontSize: '14px',
        textAlign: 'left',
    })
    row.append(title)
    // Total
    const value = Price(d.total)
    value.cssClass({
        fontSize: '14px',
    })
    row.append(value)

    const items = Div()
    items.cssClass({
        // position: 'sticky',
        // top: '0',
        zIndex: '1',
    })
    base.append(items)
    // d.items.forEach(t => {
    //     items.append(TransactionItem(t))
    // })

    return base
}