import router, { IRouteParams } from '../../../base/lib/router'
import { Div } from '../../../base/components/native/div'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import { AddClientWizard } from './shared/wizard'
import services from '../../../services'
import state from '../../../base/services/state'
import { Body } from '../../shared/body'

export const AddClientPage = () => {

    const base = Div()
    const body = Body()
    const wizard = AddClientWizard()
    body.append(wizard)
    base.append(body)

    body.cssClass({ display: 'flex', flexDirection: 'column', alignItems: 'center' })
    base.cssClass(helpers.styles.PAGE_BASE)

    wizard.on('done', async () => {
        await services.clients.add(state.get('new-client'))

        if (services.flags.get('CELEBRATE_FIRST_CLIENT_ADDED') === undefined) services.flags.set('CELEBRATE_FIRST_CLIENT_ADDED', true)
        router.back()
    })

    function exit({ to }: IRouteParams) {
        base.style(helpers.styles.PAGE_EXIT)
    }

    async function enter({ query, from, to, data }: IRouteParams) {
        wizard.reset()
        if (from === '/') base.style(helpers.styles.PAGE_EXIT)
        await waitFor(helpers.styles.PAGE_TRANSITION_DURATION)
        base.style(helpers.styles.PAGE_ENTER)
    }

    return Object.assign(base, { enter, exit })
}