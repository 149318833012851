import helpers from "../../../helpers"
import services from "../../../services"

export async function generateMessage(clientId: string, appointmentId: string) {

    const client = await services.clients.get(clientId) // Todo: why T?
    const appointment = await services.appointments.get(appointmentId)
    const name = client?.name
    const date = helpers.date.format(appointment?.date as string)
    const { from, to } = appointment?.time || { from: '', to: '' }
    const business = await services.businesses.get(appointment?.business_id as string)

    return `
    ${name} عزیز،
    <br/>
    <br/>
    شما در تاریخ ${date} از ساعت ${helpers.locale.replaceLatinDigits(from)} تا ساعت ${helpers.locale.replaceLatinDigits(to)} 
    وقت 
    ${appointment?.description || 'مشاوره'} دارید.
    <br/>
    منتظر حضور شما هستیم!
    <br/>
    <br/>
    با سپاس
    <br/>
    <span id="business">
        ${business?.name || '[نام کسب و کار]'}
    </span>
    `

}