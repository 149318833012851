import services from "."
import app from "../app"
import { jDateFormatter } from "../base/helpers/date"
import { TAppointment } from "../interfaces/appointments"
import { TClient } from "../interfaces/client"
import { TExpense } from "../interfaces/expenses"
import { IAggregatedTransaction } from "../interfaces/transactions"
import models from "../models"
import db from "./db"

export default {
    async addExpense(expense: TExpense) {
        const expenseEntity = await models.createExpense(expense)
        await db.save('transactions', expenseEntity)
        console.log(expenseEntity);

        return expenseEntity

    },
    async allExpenses() {
        const allExpenses = await db.all<TExpense>('transactions')
        let total = allExpenses.reduce((acc: number, expense: TExpense) => acc + expense.amount, 0)
        allExpenses.sort((a: any, b: any) => a.date > b.date ? -1 : 1)
        console.log(allExpenses, 'allExpenses');

        const data: IAggregatedTransaction = allExpenses.reduce((acc: IAggregatedTransaction, expense: TExpense) => {
            if (!expense.amount) expense.amount = 0
            const [year, month, day, wd] = jDateFormatter.format(new Date(expense.date)).replace(',', '').split(' ')
            if (typeof expense.amount !== 'number') {
                expense.amount = +expense.amount as any || 0
            }
            acc.total += expense.amount
            acc.count++
            let y = acc.byYear[acc.byYear.length - 1]
            if (y) {
                y.total += expense.amount
                y.count++
            } else {
                y = {
                    year,
                    total: expense.amount,
                    count: 1,
                    byMonth: []
                }
                acc.byYear.push(y)
            }
            let m = y.byMonth[y.byMonth.length - 1]
            if (m) {
                if (m.month === month) {
                    m.total += expense.amount
                    m.count++
                } else {
                    m = {
                        month,
                        total: expense.amount,
                        count: 1,
                        byDay: []
                    }
                    y.byMonth.push(m)
                }
            } else {
                m = {
                    month,
                    total: expense.amount,
                    count: 1,
                    byDay: []
                }
                y.byMonth.push(m)
            }
            let d = m.byDay[m.byDay.length - 1]
            if (d) {
                if (d.day === day) {
                    d.total += expense.amount
                    d.count++
                    d.items.push({ id: expense.id || expense.temp_id, fee: expense.amount, at: expense.created_at })
                } else {
                    d = {
                        wd,
                        day,
                        total: expense.amount,
                        count: 1,
                        items: [{ id: expense.id || expense.temp_id, fee: expense.amount, at: expense.created_at }]
                    }
                    m.byDay.push(d)
                }
            } else {
                d = {
                    wd,
                    day,
                    total: expense.amount,
                    count: 1,
                    items: [{ id: expense.id || expense.temp_id, fee: expense.amount, at: expense.created_at }]
                }
                m.byDay.push(d)
            }
            return acc
            console.log({ wd, day, month, year })

        }, { total: 0, count: 0, byYear: [] })

        return data
    },

    async all(client: TClient) {
        const appointments = await services.appointments.byClient(client.id)
        // appointments.sort((a: any, b: any) => a.date > b.date ? -1 : 1)

        // let total = appointments.reduce((acc: number, appointment: TAppointment) => acc + appointment.fee, 0)

        appointments.sort((a: any, b: any) => a.date > b.date ? -1 : 1)
        const data: IAggregatedTransaction = appointments.reduce((acc: IAggregatedTransaction, appointment: TAppointment) => {
            if (!appointment.fee) appointment.fee = 0
            const [year, month, day, wd] = jDateFormatter.format(new Date(appointment.date)).replace(',', '').split(' ')
            if (typeof appointment.fee !== 'number') {
                appointment.fee = +appointment.fee as any || 0
            }
            acc.total += parseInt(appointment.fee)
            acc.count++
            let y = acc.byYear[acc.byYear.length - 1]
            if (y) {
                y.total += parseInt(appointment.fee)
                y.count++
            } else {
                y = {
                    year,
                    total: parseInt(appointment.fee),
                    count: 1,
                    byMonth: []
                }
                acc.byYear.push(y)
            }
            let m = y.byMonth[y.byMonth.length - 1]
            if (m) {
                if (m.month === month) {
                    m.total += parseInt(appointment.fee)
                    m.count++
                } else {
                    m = {
                        month,
                        total: parseInt(appointment.fee),
                        count: 1,
                        byDay: []
                    }
                    y.byMonth.push(m)
                }
            } else {
                m = {
                    month,
                    total: parseInt(appointment.fee),
                    count: 1,
                    byDay: []
                }
                y.byMonth.push(m)
            }
            let d = m.byDay[m.byDay.length - 1]
            if (d) {
                if (d.day === day) {
                    d.total += parseInt(appointment.fee)
                    d.count++
                    d.items.push({ id: appointment.id, fee: appointment.fee, at: appointment.at })
                } else {
                    d = {
                        wd,
                        day,
                        total: parseInt(appointment.fee),
                        count: 1,
                        items: [{ id: appointment.id, fee: appointment.fee, at: appointment.at }]
                    }
                    m.byDay.push(d)
                }
            } else {
                d = {
                    wd,
                    day,
                    total: parseInt(appointment.fee),
                    count: 1,
                    items: [{ id: appointment.id, fee: appointment.fee, at: appointment.at }]
                }
                m.byDay.push(d)
            }
            return acc
            console.log({ wd, day, month, year })

        }, { total: 0, count: 0, byYear: [] })

        return data
    },

    unSynced() {
        return db.find<TExpense>('transactions', { index: 'synced', value: 0 })
    },

    async get(remoteId: string | undefined, tempId: string | undefined = remoteId): Promise<TExpense | null> {
        const [foundById] = await db.find<TExpense>('transactions', { index: 'id', value: remoteId })

        if (foundById) return foundById
        return db.byId<TExpense>('transactions', tempId)
    },
    async update<T extends TExpense>(remoteId: string | undefined, tempId: string, data: Partial<T>) {

        let existing = await this.get(remoteId, tempId)

        if (!existing) throw new Error('Transaction not found')

        return db.update('transactions', existing.temp_id, data)
    },
    async allRaw() {
        const all = await db.all<TExpense>('transactions')
        return all.filter(c => !c.deleted) // todo move to idb
    }
}