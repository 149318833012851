import { IRouteParams } from "../../../base/lib/router"
import { waitFor } from "../../../base/utils/wait"
import helpers from "../../../helpers"
import services from "../../../services"
import { Page } from "../../shared/page"
import { Tabs } from "../../shared/tabs/tabs"
import { ClientHeader } from "./shared/header"
import { AppointmentsTab } from "./shared/tabs/appointments"
import { ProfileTab } from "./shared/tabs/profile"
import { TransactionsTab } from "./shared/tabs/transactions"


export const ClientPage = () => {
    const base = Page()
    const header = ClientHeader()
    const profileTab = ProfileTab()
    const appointmentTab = AppointmentsTab()
    const transactionsTab = TransactionsTab()
    const tabs = Tabs([profileTab, appointmentTab, transactionsTab])
    base.append(header, tabs)

    function exit({ to }: IRouteParams) {
        const notHome = ['add', 'appointments', 'billing', 'edit', 'reminder'].some(v => to?.includes(v))
        return base.style(notHome ? helpers.styles.PAGE_EXIT_UP : helpers.styles.PAGE_EXIT)
    }

    async function enter({ query, from, params, to, data }: IRouteParams) {
        // if (from === '/') base.style({ ...helpers.styles.PAGE_EXIT, ...EASE(0) })
        // else base.style({ ...helpers.styles.PAGE_EXIT_UP, ...EASE(0) }).style(EASE(.16), 10)
        const client = await services.clients.get(params.clientId)
        header.setClient(client)
        profileTab.refresh(client)
        appointmentTab.refresh(client)
        transactionsTab.refresh(client)
        await waitFor(helpers.styles.PAGE_TRANSITION_DURATION)
        base.style(helpers.styles.PAGE_ENTER)
    }

    return Object.assign(base, { enter, exit })
}
