import { Div } from "../../../base/components/native/div"
import router from "../../../base/lib/router"
import images from "../../../configs/images"
import helpers from "../../../helpers"
import services from "../../../services"
import { ActionButton } from "../action-button"
import { AppointmentItem } from "./appointment-item"
import { DayTitle } from "./day-title"
import { addStyle, baseStyle, listStyle } from "./day.style"

export const Day = (date: Date) => {

    const base = Div()
    base.cssClass(baseStyle)

    const title = DayTitle(date)
    base.append(title)

    const list = Div()
    list.cssClass(listStyle)
    base.append(list)

    const today = new Date().toDateString() === date.toDateString()
    const todayTitle = today ? 'امروز' : 'این روز'
    const add = ActionButton(`ثبت نوبت جدید برای ${todayTitle}`, { icon: images.ICONS.ADD })
    add.cssClass(addStyle)
    add.on('click', () => {
        const { year, month, day } = helpers.date.getDateParts(date)
        router.goto(`/${year}-${month}-${day}/add-appointment-from-day`)
    })
    base.append(add) // Todo: prevent flickering

    services.appointments.byDate(date).then(appointments => {
        appointments.forEach(appointment => {
            const item = AppointmentItem(appointment, { showDate: false })
            list.append(item)
        })
        if (appointments.length) add.text(`ثبت نوبتی دیگر برای ${todayTitle}`)
    })

    return base
}