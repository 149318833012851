import { CS } from "../../../../../../base/utils/styler";

export const baseStyle: CS = {
    width: '100%',
    // padding: 'calc(env(safe-area-inset-top) + 15px) 60px 0 60px',
    overflow: 'hidden',
    overflowY: 'auto',
    paddingBottom: '20px'
}


export const buttonsStyle: CS = {
    padding: '60px'
}