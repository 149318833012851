import { Div } from '../../../../../base/components/native/div'
import state from '../../../../../base/services/state'
import helpers from '../../../../../helpers'
import { ActionButton } from '../../../../shared/action-button'
import { DayPicker } from '../../../../shared/calendar/day-picker/day-picker'
import { WizardSlide } from '../../../../shared/slide/slide'


export const DateSlide = () => {

    const base = WizardSlide()
    const name = Div('تاریخ مراجعه')
    const date = DayPicker()
    const next = ActionButton(helpers.strings.NEXT_STEP)
    base.append(name, date, next)


    next.on('click', () => {
        state.merge('new-appointment', { date: date.getValue() })
        base.emit('next')
    })
    // base.on('enter', () => setTimeout(name.focus, 450))
    // base.on('reset', () => name.clear())
    name.cssClass({
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '10px',
    })
    next.cssClass({
        marginTop: '30px',
    })
    base.cssClass({
        padding: '15px 60px',
    })

    return base
}