import { createEmitter } from '../base/utils/emitter'
import { AppEventMap } from '../interfaces/app-events'
import { TAppointment } from '../interfaces/appointments'
import { createAppointment } from '../models/appointment'
import db from './db'

async function all(page: number) {
    const all = await db.all<TAppointment>('appointments')
    return all.filter(c => !c.deleted) // todo move to idb
}

async function get(remoteId: string | undefined, tempId: string | undefined = remoteId): Promise<TAppointment | null> {
    const [foundById] = await db.find<TAppointment>('appointments', { index: 'id', value: remoteId })

    if (foundById) return foundById
    return db.byId<TAppointment>('appointments', tempId)
}

async function add(data: TAppointment) {
    const appointment = await createAppointment(data)
    const saved = await db.save('appointments', appointment)
    new Promise(r => setTimeout(r, 500))
    return saved
}

async function update<T extends TAppointment>(remoteId: string | undefined, tempId: string, data: Partial<T>) {

    let existing = await get(remoteId, tempId)

    if (!existing) throw new Error('Appointment not found')

    return db.update('appointments', existing.temp_id, data)
}

async function byDate(date: Date) {
    const _date = new Date(date)
    _date.setUTCHours(0, 0, 0, 0)
    const value = _date.toISOString().split('T')[0]
    const data = await db.find<TAppointment>('appointments', { index: 'date', value })
    const sorted = data.sort((a: any, b: any) => a.time.from > b.time.from ? 1 : -1)
    return sorted.filter((a: any) => !a.deleted) // t
}

function unSynced() {
    return db.find<TAppointment>('appointments', { index: 'synced', value: 0 })
}

async function remove(localOrServerId: string) {
    let existing = await get(localOrServerId)
    if (!existing) throw new Error('Appointment not found')
    return update(existing.id, existing.temp_id, { ...existing, deleted: 1 })
}

async function byClient(clientLocalOrRemoteId: string) {
    const foundById = await db.find<TAppointment>('appointments', { index: 'client_id', value: clientLocalOrRemoteId })
    if (foundById.length) return foundById.filter((a: any) => !a.deleted)
    const foundByTempId = await db.find<TAppointment>('appointments', { index: 'client_id', value: clientLocalOrRemoteId })
    if (foundByTempId.length) return foundByTempId.filter((a: any) => !a.deleted)
    return []
    // todo fix idb multiple indexes filter
}

export default {
    all,
    byClient,
    get,
    add,
    update,
    byDate,
    unSynced,
    remove,
    ...createEmitter<AppEventMap>()


}