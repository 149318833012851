import { CS } from "../../../base/utils/styler";

export const baseStyle: CS = {
    margin: '10px 44px 60px 25px',
    borderRight: `1px solid #ea4c89`,
    padding: '0 15px',
    cursor: 'pointer',
    textAlign: 'right',
    position: 'relative',
}

export const timeStartStyle: CS = {
    position: 'absolute',
    top: '-5px',
    right: '-48px',
    fontSize: '14px',
    color: '#666',
    opacity: '0.8'
}

export const timeEndStyle: CS = {
    position: 'absolute',
    bottom: '-5px',
    right: '-48px',
    fontSize: '14px',
    color: '#666',
    opacity: '0.8'
}

export const clientStyle: CS = {
    fontSize: '18px',
    fontWeight: 'bold',
    position: 'relative',
    top: '-10px',
}

export const dateStyle: CS = {
    fontSize: '18px',
    fontWeight: 'bold',
    position: 'relative',
    top: '-10px',
}

export const descriptionStyle: CS = {
    fontSize: '16px',
    margin: '0px 0 14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    maxHeight: '100px',
    overflowY: 'auto',
    WebkitBoxOrient: 'vertical'
}

export const feeStyle: CS = {
    fontSize: '18px',
    color: '#ea4c89',
    margin: '0px 0 14px',
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    alignItems: 'center',
}

export const arrowStyle: CS = {
    position: 'absolute',
    opacity: '0.3',
    top: 'calc(50% - 26px)',
    left: '-20px',
    // transform: 'rotate(22.5deg)',
}

