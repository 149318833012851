export default {
    INTRO: `
    مشتریان شما می‌توانند از طریق تلگرام، از جلسات و نوبت‌های آینده خود مطلع شوند.
    <br/>
    برای این کار، کافی است لینک بات تلگرام فلپ را برایشان ارسال کنید تا عضو شوند.
    <br/>
    `,
    SEND_LINK_TO_CLIENT: `
    لطفا لینک زیر را برای مشتری ارسال کنید تا عضو بات تلگرامی شود و یادآوری‌ها را دریافت کند.
    `,
    JOIN_TEXT: 'برای اطلاع‌رسانی سریع و به‌موقع درباره نوبت‌های خود و دریافت مطالب سودمند و به‌روز، لطفاً با کلیک روی لینک زیر به بات تلگرامی ما بپیوندید:'
}
