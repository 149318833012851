import { CS } from "../../../../base/utils/styler";

export const keyStyle: CS = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '40px',
    gap: '16px',
}

export const baseStyle: CS = {
    padding: '0px 60px',
}

export const descStyle: CS = {
    margin: '60px 0 -20px',
}