import { Div } from "../../base/components/native/div";
import ldb from "../../base/lib/ldb";
import router, { IRouteParams } from "../../base/lib/router";
import { waitFor } from "../../base/utils/wait";
import helpers from "../../helpers";
import { createBusiness } from "../../models/business";
import services from "../../services";
import db from "../../services/db";
import { EditClient } from "./components/client";
import { EditDate } from "./components/date";
import { EditPhone } from "./components/phone";
import { EditPrice } from "./components/price";
import { EditText } from "./components/text";
import { EditTime } from "./components/time";

let afterLoadHistoryLength = 0
window.onload = () => afterLoadHistoryLength = history.length

export const EditPage = () => {

    const base = Div()
    base.cssClass(helpers.styles.PAGE_BASE)

    function exit() {
        base.style(helpers.styles.PAGE_EXIT)
    }

    async function enter({ params }: IRouteParams) {

        base.empty()
        const lead = params.appointmentId ? 'appointments' : 'clients'
        const id = params.appointmentId || params.clientId

        const entity = await services[lead as 'clients' | 'appointments'].get(id)
        if (!entity) {
            router.back()
            return
        }
        const value = getValue(entity, params.field)

        const fieldEditors: any = {
            'contact.cell': EditPhone,
            'contact.telegram': EditPhone,
            'business': EditText,
            'fee': EditPrice,
            'date': EditDate,
            'time': EditTime,
            'client_id': EditClient,
        };

        const editorFunction = fieldEditors[params.field] || EditText
        const edit = editorFunction('ویرایش ' + getLabel(params.field), value);

        base.append(edit)
        edit.on('update', async () => {
            if (params.field === 'business') {
                handleAddOrUpdateBusiness(edit.getValue())
            } else {
                await services[lead as 'clients' | 'appointments'].update(id, id, { // todo: check it
                    ...entity,
                    [params.field]: edit.getValue(),
                    synced: 0,
                    updated_at: new Date().toISOString()
                })
            }
            await services.sync.run()
            router.back()
            // todo: move to history service
            // if (history.length === initialHistoryLength) router.back()
            // else router.goto(location.href.replace('/edit/' + params.field, ''))
        })
        edit.on('cancel', () => router.back())

        await waitFor(helpers.styles.PAGE_TRANSITION_DURATION)
        base.style(helpers.styles.PAGE_ENTER)
    }

    return Object.assign(base, { enter, exit })
}

function getValue(entity: any, field: string) {
    return field.split('.').reduce((obj, key) => obj && obj[key], entity);
}

function getLabel(field: string) {
    switch (field) {
        case 'name':
            return 'نام'
        case 'description':
            return 'شرح کار'
        case 'fee':
            return 'دستمزد'
        case 'date':
            return 'تاریخ'
        case 'time':
            return 'ساعت'
        case 'contact.cell':
            return 'شماره موبایل'
        case 'contact.telegram':
            return 'شماره تلگرام'
        case 'id':
            return 'مشتری'
        case 'business':
            return 'نام کسب و کار شما'
        case 'service-title':
            return 'خدماتی که ارائه می‌دهید'
        default:
            return 'فیلد'
    }
}

async function handleAddOrUpdateBusiness(name: string) {
    const [business] = await services.businesses.all(0) // for now that we have only one business
    const payload = {
        ...business,
        name,
        synced: 0,
        updated_at: new Date().toISOString()
    }

    await services.businesses.update(business.id, business.temp_id, payload)
}