import { fetch } from '../base/lib/fetch'
import idb from '../base/lib/idb'
import ldb from '../base/lib/ldb'
import { XHR } from '../base/lib/xhr'
import { emitter } from '../base/utils/emitter'
import { TAppointment } from '../interfaces/appointments'
import { TUser } from '../interfaces/user'
import appointments from './appointments'
import businesses from './businesses'
import clients from './clients'
import db from './db'
import sync from './sync'
import transactions from './transactions'

let popup: Window

export default {
    async check() {
        return XHR.post('/auth/check')
    },

    requestLogin(phone: string) {
        return XHR.post('/auth/request-login', { phone })
        // fetch('/auth/request-login', { method: 'POST', body: JSON.stringify({ phone }) })   
    },

    async login(phone: string, code: string) {
        try {
            const { ok, data } = await XHR.post('/auth/login', { phone, code }) as { ok: boolean, data: TUser }
            if (!ok) return false
            ldb.save('user', data)

            // Ensure all local records have user_id
            await ensureUserIdOnAllRecords(data.id)
            console.log('ensureUserIdOnAllRecords done');

            sync.run()
            return data
        } catch (error) {
            console.error(error)
            return false
        }
    },
    // todo: check auto login

    async logout() {
        // Todo clear components
        ldb.clear()
        db.clear('clients')
        db.clear('appointments')
        db.clear('businesses')
        db.clear('transactions')
        return XHR.post('/auth/logout')
    }

    // async googleLogin() {
    //     const W = Math.max(window.innerWidth, 600)
    //     const H = Math.max(window.innerHeight, 800)
    //     const { data } = await XHR.get('/auth/google', { type: 'text/html' })
    //     popup = <Window>window.open('', 'Rankr authentication', `status=1, height=${H}, width=${W}, toolbar=0, resizable=0`)
    //     popup.location.href = data
    //     return true
    // }
}
async function ensureUserIdOnAllRecords(userId: string) {
    // Appointments
    const allAppointments = await appointments.all(0) as TAppointment[]
    for (const appointment of allAppointments) {
        if (!appointment.user_id) {
            await appointments.update(appointment.id, appointment.temp_id, { ...appointment, user_id: userId })
        }
    }

    // Clients
    const allClients = await clients.all(0)
    for (const client of allClients) {
        if (!client.user_id) {
            await clients.update(client.id, client.temp_id, { ...client, user_id: userId })
        }
    }

    // Businesses
    const allBusinesses = await businesses.all(0)
    for (const business of allBusinesses) {
        if (!business.user_id) {
            await businesses.update(business.id, business.temp_id, { ...business, user_id: userId })
        }
    }

    // Transactions
    const allTransactions = await transactions.allRaw()
    for (const transaction of allTransactions) {
        if (!transaction.user_id) {
            await transactions.update(transaction.id, transaction.temp_id, { ...transaction, user_id: userId })
        }
    }
}

// window.addEventListener('message', (event) => {
//     return // temp
//     if (event?.origin !== window.origin) return
//     popup?.close()
//     getProfile(event.data)
//     return true
// }, false)

// async function getProfile(token: string) {
//     const { data } = await XHR.get('/auth/google/callback?code=' + token)
//     ldb.save(data).as('rankr-user')
//     emitter.emit('logged-in', data)
// }