import { Div } from "../../base/components/native/div";
import { Img } from "../../base/components/native/img";
import { EASE } from "../../base/helpers/style";
import { IRouteParams } from "../../base/lib/router";
import { waitFor } from "../../base/utils/wait";
import images from "../../configs/images";
import helpers from "../../helpers";
import { ActionButton } from "../shared/action-button";

export const AboutPage = () => {
    const base = Div()
    const title = Div('فـلِـپـــ')
    const description = Div(`
    فلپ همراه کسب و کار شماست!
    <br/>
    <br/>
    به کمک آن می‌توانید:
<br/>
<br/>
<div style="font-size: 18px; font-weight: bold; width:170px; margin:0 auto; line-height:22px; color:#ea4c89">
مشتریان خود را ثبت و مدیریت کنید    <br/><br/>
برای قرارها یادآور خودکار بفرستید<br/><br/>
به مشتریان به سادگی نوبت دهید<br/><br/>
جلسات کاری را ثبت و پیگیری کنید<br/><br/>
ارتباط با مشتریان را مدیریت کنید<br/><br/>
درآمدها را محاسبه و ثبت نمایید<br/><br/>
هزینه‌ها را پیگیری و مدیریت کنید<br/><br/>
گزارش‌های دخل و خرج را مشاهده کنید<br/><br/>
با آنها چت پشتیبانی داشته باشید

</div>

<h2>فلپ برای چه کسب و کارهایی مناسب است؟</h2>
پزشکان،
مشاوران،
تراپیست‌ها،
وکلا،
معلمان،
آرایشگران،
مربیان خصوصی،
ماساژورها،
ناخن‌کاران،
برگزارکنندگان رویدادها،
مراکز آموزشی،
...






<br/>
<br/>
فلپ سعی می‌کند  همه آنچه برای مدیریت کارآمد کسب و کار کوچک و متوسط  نیاز دارید را در یک اپلیکیشن گرد هم آورد.






    `)
    // body.style({ paddingTop: '60px' })

    base.append(title, description)

    title.cssClass({
        fontSize: '36px',
        fontWeight: 'bold',
    })
    description.cssClass({
        fontSize: '16px',
        margin: '10px 0 30px',
        width: '290px',
    })

    base.cssClass({
        ...helpers.styles.PAGE_BASE,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        overflowY: 'auto',
    })

    return Object.assign(base, {
        ...base,
        async exit({ to = '' }: IRouteParams) {
            base.style(helpers.styles.PAGE_EXIT)
        },
        async enter({ from = '' }: IRouteParams) {
            await waitFor(200)
            // if (from === '/menu') {
            //     base.style({ ...helpers.styles.PAGE_EXIT, ...EASE(0) })
            // }
            base.style({ ...helpers.styles.PAGE_ENTER, ...EASE(.16) }, 50)

        }
    })
}
