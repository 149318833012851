import { Div } from "../../../../../base/components/native/div"
import state from "../../../../../base/services/state"
import helpers from "../../../../../helpers"
import { TAppointment } from "../../../../../interfaces/appointments"
import { TClient } from "../../../../../interfaces/client"
import services from "../../../../../services"
import { ActionButton } from "../../../../shared/action-button"
import { Select } from "../../../../shared/select/select"
import { WizardSlide } from "../../../../shared/slide/slide"


export const UserSlide = () => {

    const base = WizardSlide()
    const name = Div('انتخاب مشتری/مراجع')
    const clientsSelect = Select()
    const next = ActionButton(helpers.strings.NEXT_STEP)
    base.append(name, clientsSelect, next)

    next.on('click', () => {
        const { id, temp_id } = clientsSelect.getValue()
        state.merge<TAppointment>('new-appointment', {
            client_id: id || temp_id,
        })
        base.emit('next')
    })
    name.cssClass({
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '10px',
    })
    next.cssClass({
        marginTop: '30px',
    })
    base.cssClass({
        padding: '15px 60px',
    })

    base.on('enter', () => {
        services.clients.all(0).then((clients) => {
            const mapped = clients.map((client: TClient) => ({ ...client, key: client.temp_id || client.id }))
            clientsSelect.setValue(mapped, clients[0].temp_id || clients[0].id)
        })
    })

    return base
}