import { CS } from "../../../base/utils/styler"

export const labelDivStyle: CS = {
    fontSize: '14px',
    color: 'gray',
}
export const valueDivStyle: CS = {
    flexDirection: 'rtl',
    fontSize: '18px',
    color: 'black',
    marginBottom: '10px',
    // direction: type === 'text' ? 'rtl' : 'ltr',
}
export const editStyle: CS = {
    position: 'absolute',
    right: '20px',
    top: '20px',
    opacity: '0.45',
    // display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '14px',
    gap: '4px',
    backgroundColor: 'white',
    display: 'none',
}
export const baseStyle: CS = {
    textAlign: 'right',
    paddingRight: '60px',
    marginTop: '24px',
    position: 'relative',
}
