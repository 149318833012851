import services from "."
import ldb from "../base/lib/ldb"
import { BaseEventMap, createEmitter } from "../base/utils/emitter"

const flagsEmitter = createEmitter<TFlagsEvents>()
export default {
    all(): { [key in keyof TFlags]: boolean } {
        return ldb.get('FLAGS') || {}
    },
    get(key: keyof TFlags): boolean {
        return ldb.get('FLAGS')[key]
    },
    set(key: keyof TFlags, value: boolean) {
        const flags = ldb.get('FLAGS') || {}
        flags[key] = value
        ldb.set('FLAGS', flags)
        flagsEmitter.emit('changed', { flags })
    },
    ...flagsEmitter
}

type TFlags = {
    CELEBRATE_FIRST_CLIENT_ADDED: boolean
    CELEBRATE_FIRST_EVENT_ADDED: boolean
}

interface TFlagsEvents extends BaseEventMap {
    // Todo exclude events from BaseEventMap
    'changed': { flags: { [key in keyof TFlags]: boolean } }
}