import { CS } from "../../../base/utils/styler";

export const baseStyle: CS = {
    // transition: 'all 0.3s',
    // opacity: '0',
    position: 'relative',
    border: '1px solid rgb(113, 113, 113)',
    width: 'fit-content',
    borderRadius: '25px',
    padding: '5px 10px',
    fontSize: '13px',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    height: '32px',
    gap: '5px',
    '&:active': {
        transform: 'scale(0.99)'
    }
}