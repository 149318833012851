import app from './app'
import ldb from './base/lib/ldb'
import { disableContextMenu, disableTouchStartPassive } from './base/utils/event-handlers'
import { uuidv4 } from './base/utils/id-generator'
import services from './services'
import db from './services/db'
import flags from './services/flags'
import sync from './services/sync'

(async () => {
    window.onerror = (message, source, lineno, colno, error) => alert(message)
    await db.init()
    services.sync.run() // Todo: run every 5 seonds?
    disableContextMenu({ touch: true, mouse: false })
    disableTouchStartPassive()
    document.body.appendChild(app.el)
    window.ononline = () => app.emit('online')
    if (!ldb.get('app-id')) ldb.set('app-id', uuidv4())
    await services.log.userEvents('open')
})()

window.addEventListener('beforeunload', () => services.log.userEvents('close'))


