import { Div } from "../../../../../base/components/native/div"
import state from "../../../../../base/services/state"
import helpers from "../../../../../helpers"
import { ActionBack } from "../../../../shared/action-back"
import { ActionButton } from "../../../../shared/action-button"
import { DInput } from "../../../../shared/d-input"
import { WizardSlide } from "../../../../shared/slide/slide"


export const DescSlide = () => {

    const base = WizardSlide()
    const desc = DInput('شرح هزینه', 'توضیح مختصر یا عنوان مورد هزینه', { size: 20, type: 'textarea' })
    const next = ActionButton(helpers.strings.SAVE)
    const prev = ActionBack(helpers.strings.PREV_STEP)
    const keys = Div()
    keys.append(next, prev)
    base.append(desc, keys)

    next.on('click', () => { state.merge('new-expense', { description: desc.getValue() }); base.emit('done') })
    prev.on('click', () => base.emit('prev'))
    desc.on('input', () => desc.getValue().length < 2 ? next.disable() : next.enable())
    base.on('enter', () => setTimeout(desc.focus, 450))
    base.on('enter', () => desc.getValue().length < 2 && next.disable())
    base.on('reset', () => desc.clear())

    keys.cssClass({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '40px',
        gap: '16px',
    })
    base.cssClass({
        padding: '15px 60px',
    })

    return base
}