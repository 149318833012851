import { Base } from "../../../../../../base/components/base"
import images from "../../../../../../configs/images"


export const ProfileImage = () => {
    const base = Base()
    let W = window.innerWidth - 100
    if (W > 160) W = 160
    base.cssClass({
        width: W + 'px',
        height: W + 'px',
        borderRadius: '50%',
        margin: 'calc(70px + env(safe-area-inset-top)) auto 30px',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        opacity: '0.2',
        backgroundImage: `url(${images.BANNERS.PROFILE_GRAY})`,
    })

    return base
}