import { Div } from '../../../../../base/components/native/div'
import state from '../../../../../base/services/state'
import helpers from '../../../../../helpers'
import strings from '../../../../../helpers/strings'
import { ActionBack } from '../../../../shared/action-back'
import { ActionButton } from '../../../../shared/action-button'
import { DInput } from '../../../../shared/d-input'
import { WizardSlide } from '../../../../shared/slide/slide'

export const PhoneSlide = () => {

    const base = WizardSlide()
    const cell = DInput('شماره موبایل', '09123467890', { size: 36, pattern: '[0-9]*', inputmode: 'numeric' })
    const next = ActionButton(strings.NEXT_STEP)
    const prev = ActionBack(strings.PREV_STEP)
    const keys = Div()
    const test = ActionButton('test')
    test.on('click', () => {
        state.merge('new-client', {
            contact: {
                cell: '09121994018'
            }
        });
        base.emit('next')
    })
    keys.append(next, prev/* , test */)
    base.append(cell, keys)

    next.disable()
    next.on('click', handleNext)
    prev.on('click', () => base.emit('prev'))
    cell.on('input', ({ value }: any) => isValueValid(value) ? next.enable() : next.disable())
    cell.on('key-enter', handleNext)
    base.on('enter', () => setTimeout(() => cell.focus(), 450))
    base.on('reset', () => cell.clear())

    function handleNext() {
        if (!isValueValid(cell.getValue())) return
        const cellValue = cell.getValue();
        const formattedValue = helpers.locale.replacePersianDigits(cellValue);
        state.merge('new-client', {
            contact: {
                cell: formattedValue
            }
        });
        base.emit('next')
    }

    function isValueValid(value: string) {
        return value.match(/^۰۹[۰-۹]{9}$/)
    }

    keys.cssClass({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '40px',
        gap: '16px',
    })

    return base
}