import { Div } from "../../../base/components/native/div"
import { Img } from "../../../base/components/native/img"
import router from "../../../base/lib/router"
import images from "../../../configs/images"
import constatnts from "../../../constatnts"
import { TAppointment } from "../../../interfaces/appointments"
import services from "../../../services"
import { Loading } from "../loading"
import { baseStyle } from "./index.style"

export const AppointmentReminder = async (item: TAppointment) => {

    // different logics / different routes
    const base = Div()
    base.cssClass(baseStyle)

    const bellIcon = Img(images.ICONS.BELL, { width: 18 })
    base.append(bellIcon)

    const title = Div()
    base.append(title)

    const loading = Loading(undefined, 40)
    base.append(loading)

    const theClient = await services.clients.get(item.client_id)

    base.el.onclick = (e) => {
        e.stopPropagation()
        // Check different targets
        router.goto(`/clients/${item.client_id}/reminders/${item.id || item.temp_id}`)
    }

    if (theClient?.notifications_enabled === 0) {
        loading.remove()
        title.text(constatnts.notifs.SETUP_NOTIFICATIONS)
        return base
    }

    const { data, status } = await services.reminder.getSubscriptionStatus((theClient?.contact?.cell || '').replace('0', '98'))
    loading.remove()
    if (status === 403 || status === 401 || !data) {
        // unauthorized
        title.text(constatnts.notifs.WAITING_FOR_USER_TO_JOIN)
        return base
    }



    if (!data.chat_id) {
        // user has not joined the bot
        title.text(constatnts.notifs.WAITING_FOR_USER_TO_JOIN)
        return base
    }

    const { data: delivered, status: deliveryRequestStatus } = await services.reminder.getDeliveryStatus(item.id || item.temp_id)

    if (deliveryRequestStatus === 404) {
        title.text(constatnts.notifs.NOTIFICATION_NOT_SENT)
        return base
    }
    if (delivered?.length) {
        title.text(constatnts.notifs.NOTIFICATION_DELIVERED)
        return base
    }

    title.text(constatnts.notifs.NOTIFICATION_SCHEDULED)




    return base
}