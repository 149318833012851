import state from '../../../../../base/services/state'
import helpers from '../../../../../helpers'
import { ActionButton } from '../../../../shared/action-button'
import { DInput } from '../../../../shared/d-input'
import { WizardSlide } from '../../../../shared/slide/slide'

export const NameSlide = () => {

    const base = WizardSlide()
    base.on('enter', () => setTimeout(name.focus, 450))
    base.on('reset', () => name.clear())

    const name = DInput('مشتری جدید', 'نـام و نـام خانوادگی')
    name.on('input', ({ value }: any) => value.length > 0 ? next.enable() : next.disable())
    name.on('key-enter', handleNext)

    const next = ActionButton(helpers.strings.NEXT_STEP)
    next.disable()
    next.on('click', handleNext)
    next.cssClass({
        marginTop: '40px',
    })

    function handleNext() {
        if (name.getValue().length === 0) return
        state.set('new-client', { name: name.getValue() })
        base.emit('next')
    }

    base.append(name, next)

    return base
}