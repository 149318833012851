import { withRipple } from "../../../base/components/advanced/ghost/ghost"
import { Div } from "../../../base/components/native/div"
import { Img } from "../../../base/components/native/img"
import { CENTER, EASE } from "../../../base/helpers/style"

export const TabsNavItem = (label: string, id: string, icon: string, selected = false) => {

    const base = Div()
    const iconEl = Img(icon, { width: 24 })
    const labelEl = Div(label)
    base.append(iconEl, labelEl)

    base.el.onclick = () => base.emit('click', id)
    withRipple(base, { bg: 'white' })
    base.cssClass({
        position: 'relative',
        fontWeight: '100',
        fontSize: '14px',
        width: '80px',
        textAlign: 'center',
        opacity: '0.4',
        flexDirection: 'column',
        ...CENTER,
        ...EASE(.16)
    })
    labelEl.cssClass({
        userSelect: 'none',
    })

    function select() {
        base.style({ opacity: '1' })
    }
    function deselect() {
        base.style({ opacity: '0.4' })
    }
    if (selected) select()

    return Object.assign(base, {
        select,
        deselect,
        selected,
        id
    })
}
