import { Page } from '../shared/page'
import { ClientsTab } from './clients-tab'
import { CalendarTab } from './calendar-tab'
import { Tabs } from '../shared/tabs/tabs'
import { HomeHeader } from './shared/header'
import services from '../../services'
import { TransactionsTab } from './transactions-tab'

export const HomePage = () => {

    const base = Page()
    base.append()

    const header = HomeHeader()
    base.append(header)

    const clients = ClientsTab()
    const calendar = CalendarTab()
    const transactions = TransactionsTab()
    const tabs = Tabs([calendar, clients, transactions])
    tabs.hideNav()
    base.append(tabs)


    base.on('enter', async () => {
        const countClients = await services.clients.count()
        // const { CELEBRATE_FIRST_CLIENT_ADDED } = services.flags.all()
        if (!countClients) {
            tabs.switchTo('clients')
            clients.refresh()
            transactions.refresh()
        } else {
            tabs.showNav()
            clients.refresh()
            calendar.refresh()
            transactions.refresh()
        }
    })

    return base
}