import services from "."
import app from "../app"
import ldb from "../base/lib/ldb"
import { XHR } from "../base/lib/xhr"
import { TAppointment } from "../interfaces/appointments"
import { TClient } from "../interfaces/client"
import appointments from "./appointments"
import clients from "./clients"

export default {
    async run() {
        // 1. Check user
        const user = ldb.get('user')
        if (!user) return

        // 2. Get last sync date or default to epoch
        const lastSync = ldb.get('lastSync') || new Date(0).toISOString()

        // 3. Push local unsynced changes
        await pushLocalChanges()

        // 4. Pull server changes since lastSync
        await pullServerChanges(lastSync)

        // 5. Update lastSync
        ldb.set('lastSync', new Date().toISOString())
    }
}

async function pushLocalChanges() {
    // 0. Push unsynced businesses
    const unsyncedBusinesses = await services.businesses.unSynced()
    if (unsyncedBusinesses.length) {
        await XHR.post('/api/businesses/sync', { businesses: unsyncedBusinesses })
    }

    // 1. Push unsynced clients
    const unSyncedClients = await services.clients.unSynced()
    if (unSyncedClients.length) {
        await XHR.post('/api/clients/sync', { clients: unSyncedClients })
    }

    // 2. Push unsynced appointments
    const unsyncedAppointments = await services.appointments.unSynced()
    if (unsyncedAppointments.length) {
        await XHR.post('/api/appointments/sync', { appointments: unsyncedAppointments })
    }

    // 3. Push unsynced transactions
    const unsyncedTransactions = await services.transactions.unSynced()
    if (unsyncedTransactions.length) {
        await XHR.post('/api/transactions/sync', { transactions: unsyncedTransactions })
    }

    // 3. Mark local businesses as synced
    for (const business of unsyncedBusinesses) {
        await services.businesses.update(business.id, business.temp_id, { ...business, synced: 1 })
    }

    // 3. Mark local clients as synced
    for (const client of unSyncedClients) {
        await clients.update(client.id, client.temp_id, { ...client, synced: 1 })
    }

    // 4. Mark local appointments as synced
    for (const appointment of unsyncedAppointments) {
        await appointments.update(appointment.id, appointment.temp_id, { ...appointment, synced: 1 })
    }

    // 5. Mark local transactions as synced
    for (const transaction of unsyncedTransactions) {
        await services.transactions.update(transaction.id, transaction.temp_id, { ...transaction, synced: 1 })
    }
}

async function pullServerChanges(lastSync: string) {
    // 0. Pull new/updated businesses
    const { data: newBusinesses } = await XHR.get(`/api/businesses/sync?since=${lastSync}`)
    for (const business of newBusinesses) {
        handleUpdateBusiness(business)
    }

    // 1. Pull new/updated clients
    const { data: newClients } = await XHR.get(`/api/clients/sync?since=${lastSync}`)
    for (const client of newClients) {
        handleUpdateClient(client)
    }

    // 2. Pull new/updated appointments
    const { data: newAppointments } = await XHR.get(`/api/appointments/sync?since=${lastSync}`)
    for (const appointment of newAppointments) {
        handleUpdateAppointment(appointment)
    }

    // 3. Pull new/updated transactions
    const { data: newTransactions } = await XHR.get(`/api/transactions/sync?since=${lastSync}`)
    for (const transaction of newTransactions) {
        handleUpdateTransaction(transaction)
    }
}

async function handleUpdateBusiness(business: any) {
    const localBusiness = await services.businesses.get(business.id, business.user_id)
    if (!localBusiness) return await services.businesses.add(business)
    if (localBusiness.updated_at <= business.updated_at) {
        await services.businesses.update(business.id, business.temp_id, business)
    }
}

async function handleUpdateClient(client: TClient) {
    const localClient = await services.clients.get(client.id, client.temp_id)
    if (!localClient) return await services.clients.add(client)
    if (localClient.updated_at <= client.updated_at) {
        await services.clients.update(client.id, client.temp_id, client)
    }
}

async function handleUpdateAppointment(appointment: TAppointment) {
    const localAppointment = await services.appointments.get(appointment.id, appointment.temp_id)

    if (!localAppointment) {
        return await services.appointments.add(appointment)
    }

    if (localAppointment.updated_at <= appointment.updated_at) {
        // set _client_id
        const localClient = await services.clients.get(appointment.client_id)
        const localBusiness = await services.businesses.get(appointment.business_id)
        await services.appointments.update(localAppointment.id, localAppointment.temp_id, {
            ...appointment,
            client_id: localClient?.id,
            business_id: localBusiness?.id
        })
        // schedule a sync again because we have updated the appointment
    }
}

async function handleUpdateTransaction(transaction: any) {
    const localTransaction = await services.transactions.get(transaction.id, transaction.temp_id)

    if (!localTransaction) {
        return await services.transactions.addExpense(transaction) // todo: use add
    }

    if (localTransaction.updated_at <= transaction.updated_at) {
        // set _client_id
        const localClient = await services.clients.get(transaction.client_id)
        const localBusiness = await services.businesses.get(transaction.business_id)
        await services.transactions.update(localTransaction.id, localTransaction.temp_id, {
            ...transaction,
            client_id: localClient?.id,
            business_id: localBusiness?.id
        })
        // schedule a sync again because we have updated the appointment
    }
}

/* 
Your code is definitely on the right track. The only “missing piece” is making sure that if a new \_id is assigned to a client, you also propagate that to any local appointments referencing that client. That way your local data remains fully consistent—and you don’t wait around for a future server “pull” to fix references.

Otherwise, everything looks solid!
*/