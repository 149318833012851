import { IBaseComponent } from "../../../base/components/base"
import images from "../../../configs/images"
import { Days } from "../../shared/days/days"
import { Tab } from "../../shared/tabs/tab"
import { Months } from "../../shared/months/months"
import { emitter } from "../../../services/emitter"
import * as jalaaliJs from "../../../lib/jalaali-js"
import state from "../../../base/services/state"
import { Div } from "../../../base/components/native/div"
import { TClient } from "../../../interfaces/client"
import services from "../../../services"
import { TransactionsList } from "../../clients/[clientId]/shared/tabs/transactions/list"
import { TransactionTotal } from "../../clients/[clientId]/shared/tabs/transactions/total"
import { TransactionYear } from "../../clients/[clientId]/shared/tabs/transactions/year"
import router from "../../../base/lib/router"


export const TransactionsTab = () => {

    const base = Tab()
    base.cssClass({
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        overflowY: 'auto',
        padding: '60px 0 20px',
        position: 'relative',
    })

    const list = TransactionsList()

    async function refresh() {
        base.empty()
        const add = Div('ثبت هزینه جدید')
        add.cssClass({
            backgroundColor: 'white',
            border: '1px solid gray',
            padding: '10px',
            width: '160px',
            borderRadius: '15px',
            margin: '0 auto',
            textAlign: 'center'
        })
        add.el.onclick = () => router.goto('/transactions/expenses/add')
        base.append(add)
        console.log('a');

        const data = await services.transactions.allExpenses()
        console.log('->', { data });

        if (data.total === 0) {
            const notAddedYet = Div('هنوز هیچ هزینه‌ای ثبت نشده است.')
            notAddedYet.cssClass({
                fontSize: '16px',
                textAlign: 'center',
                margin: '50px auto',
                padding: '60px',
                color: '#999',
            })
            base.append(notAddedYet)
            return
        }
        const total = TransactionTotal(data.total)

        const line = Div('')
        line.cssClass({
            position: 'absolute',
            top: '0',
            right: '165px',
            bottom: '60px',
            width: '1px',
            backgroundColor: '#ea4c8988',
            zIndex: '6',
        })
        base.append(list)
        list.append(line)
        list.append(total)
        data.byYear.forEach(year => {
            list.append(TransactionYear(year))
        })


    }

    return Object.assign(base, {
        onFocus() {
            console.log('Clients Tab entered')
        },
        onBlur() {
            console.log('Clients Tab exited')
        },
        refresh,
        label: 'دخل و خرج',
        icon: images.ICONS.COINS,
        id: 'transactions'
    })
}