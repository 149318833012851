import { CS } from "../../../base/utils/styler";

export const baseStyle: CS = {
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    overflowY: 'auto',
    padding: '0px 0 120px 0',
    direction: 'rtl',
}

export const addStyle: CS = {
    width: 'fit-content',
    margin: '30px auto 40px',
}

export const listStyle: CS = {
    paddingTop: '60px',
    marginTop: '30px',
    marginRight: '60px',
    // borderRight: '3px dotted #ddd',
}