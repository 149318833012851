import { Base } from '../../../base/components/base'
import { Div } from '../../../base/components/native/div'
import { Img } from '../../../base/components/native/img'
import router from '../../../base/lib/router'
import state from '../../../base/services/state'
import images from '../../../configs/images'
import helpers from '../../../helpers'
import { Price } from '../price'
import { baseStyle, editStyle, labelDivStyle, valueDivStyle } from './index.style'

type PropType = 'text' | 'phone' | 'telegram' | 'price' | 'date' | 'time'
export const Prop = (label: string, type: PropType, field: string[] | string) => {

    const base = Div()
    base.cssClass(baseStyle)

    const labelDiv = Div(label)
    labelDiv.cssClass(labelDivStyle)
    base.append(labelDiv)

    const valueDiv = Div()
    valueDiv.cssClass(valueDivStyle)
    base.append(valueDiv)

    const edit = Div()
    edit.cssClass(editStyle)
    base.append(edit)

    const pen = Img(images.ICONS.EDIT, { width: 28 })
    edit.prepend(pen)

    edit.el.onclick = () => {
        let target = field
        if (Array.isArray(field)) {
            target = field[0] || field[1] || field[3] // todo: make reducer
        }
        router.goto(location.href + '/edit/' + target)
    }

    function setContent(value: string | any) {
        let content = undefined
        valueDiv.empty()
        switch (type) {
            case 'date':
                content = Div(value)
                valueDiv.append(content)
                break
            case 'time':
                content = Div(`از ساعت ${helpers.locale.replaceLatinDigits(value.from)} تا ساعت ${helpers.locale.replaceLatinDigits(value.to)}`)
                valueDiv.append(content)
                break
            case 'price':
                console.log('in price', value);

                content = Price(value)
                valueDiv.append(content)
                break
            case 'text':
                content = Div(value)
                valueDiv.append(content)
                break
            case 'phone':
                content = Div(value)
                valueDiv.append(content)
                break
            case 'telegram':
                content = Div(value)
                valueDiv.append(content)
                break
        }
    }

    function showEdit() {
        edit.style({ display: 'flex' })
        edit.el.classList.add('shake')
    }

    function hideEdit() {
        edit.style({ display: 'none' })
        edit.el.classList.remove('shake')
    }
    return Object.assign(base, {
        setContent,
        showEdit,
        hideEdit
    })
}

// <a href="https://t.me/+1234567890">Message on Telegram</a>
// <a href="tel:+1234567890">Call</a>
// <a href="mailto:
// <a href="https://wa.me/1234567890">Chat on WhatsApp</a>

