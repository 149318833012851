import { CENTER } from "../../../../base/helpers/style"
import { CS } from "../../../../base/utils/styler"

export const baseStyle: CS = {
    ...CENTER,
    padding: '15px 60px',
    flexDirection: 'column',
}

export const introStyle: CS = {
    textAlign: 'center',
    margin: '20px 0px 10px',
    fontSize: '18px',
    maxWidth: '255px'
}

export const nextStyle: CS = {
    width: '50px',
    marginTop: '40px'
}