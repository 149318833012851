import { Wizard } from '../../../shared/slide/wizard'
import { DateSlide } from './slides/date'
import { DescSlide } from './slides/desc'
import { FeesSlide } from './slides/fees'

export const AddExpenseWizard = () => {

    const date = DateSlide()
    const fee = FeesSlide()
    const desc = DescSlide()
    const base = Wizard([date, fee, desc])

    return base
}