import { Div } from "../../../../base/components/native/div"
import { Img } from "../../../../base/components/native/img"
import { CENTER } from "../../../../base/helpers/style"
import { CS } from "../../../../base/utils/styler"
import images from "../../../../configs/images"
import constatnts from "../../../../constatnts"
import helpers from "../../../../helpers"
import { ActionButton } from "../../../shared/action-button"
import { WizardSlide } from "../../../shared/slide/slide"
import { baseStyle, introStyle, nextStyle } from "./intro.styles"


export const SetReminderIntroSlide = () => {

    const base = WizardSlide()
    base.cssClass(baseStyle)

    const icon = Img(images.ICONS.BULB, { width: 66 })
    base.append(icon)

    const intro = Div(constatnts.bot.INTRO)
    intro.cssClass(introStyle)
    base.append(intro)

    const next = ActionButton(helpers.strings.NEXT_STEP)
    next.cssClass(nextStyle)
    next.on('click', () => base.emit('next'))
    base.append(next)

    return base
}