import { ActionButton, IActionButton } from "./action-button";

export const ActionBack = (text: string, options: IActionButton = {}) => {
    const base = ActionButton(text, options)
    base.cssClass({
        backgroundColor: 'white',
        color: '#999',
        boxShadow: 'none',
        fontSize: '16px',
        width: '100%',
        // border: '1px solid #9c9c9c',
    })
    return base
}