import router, { IRouteParams } from '../../../base/lib/router'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import services from '../../../services'
import state from '../../../base/services/state'
import { Page } from '../../shared/page'
import { AddAppointmentFromDayWizard } from '../../appointments/add/wizard/add-appoitnment-from-day-wizard'
import { TAppointment } from '../../../interfaces/appointments'
import { AddExpenseWizard } from './wizard/add-expense'
import { TExpense } from '../../../interfaces/expenses'

export const AddExpensePage = () => {

    let _state: any = {}
    const base = Page()

    const wizard = AddExpenseWizard()
    base.append(wizard)

    wizard.on('done', async () => {
        const expense = state.get('new-expense')
        const results = await services.transactions.addExpense(expense as TExpense)
        services.sync.run()
        router.back()
    })

    function exit({ to }: IRouteParams) {
        base.style(helpers.styles.PAGE_EXIT)
    }

    async function enter({ params, from, to, data }: IRouteParams) {
        wizard.reset()
        // if (!params.date) return router.back()
        state.set('new-expense', {})
        _state.from = from
        if (from === '/') base.style(helpers.styles.PAGE_EXIT)
        await waitFor(helpers.styles.PAGE_TRANSITION_DURATION)
        base.style(helpers.styles.PAGE_ENTER)
    }

    return Object.assign(base, { enter, exit })
}