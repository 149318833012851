import router from "../../../../../../base/lib/router"
import images from "../../../../../../configs/images"
import helpers from "../../../../../../helpers"
import { TClient } from "../../../../../../interfaces/client"
import services from "../../../../../../services"
import clients from "../../../../../../services/clients"
import { EditRemove } from "../../../../../shared/edit-remove"
import { Prop } from "../../../../../shared/prop"
import { Tab } from "../../../../../shared/tabs/tab"
import { ProfileImage } from "./image"
import { baseStyle, buttonsStyle } from "./index.style"

export const ProfileTab = () => {

    const base = Tab()
    base.cssClass(baseStyle)

    const image = ProfileImage()
    base.append(image)

    const name = Prop('نـام و نام خانوادگی', 'text', 'name')
    base.append(name)

    const phone = Prop('تلفن', 'phone', 'contact.cell')
    base.append(phone)

    const telegram = Prop('تلگرام', 'telegram', 'contact.telegram')
    base.append(telegram)

    const editRemove = EditRemove()
    editRemove.on('edit', handleEdit)
    editRemove.on('edit-end', handleEditEnd)
    editRemove.on('remove', handleRemove)

    editRemove.cssClass(buttonsStyle)
    base.append(editRemove)

    let t: NodeJS.Timeout
    async function handleEdit() {
        name.showEdit()
        phone.showEdit()
        telegram.showEdit()
        clearTimeout(t)
        t = setTimeout(handleEditEnd, 2000);
    }

    async function handleEditEnd() {
        name.hideEdit()
        phone.hideEdit()
        telegram.hideEdit()
    }

    async function handleRemove() {
        try {
            let id = ''
            const regex = /clients\/([^\/]+)\/?$/;
            const match = location.pathname.match(regex);
            if (match) {
                id = match[1]
            }
            if (!id) throw new Error('Client not found')
            await clients.remove(id)
            services.sync.run()
            router.back()
        } catch (error) {
            alert(error.message)
        }
    }

    function refresh(client: TClient) {
        name.setContent(client.name)
        phone.setContent(helpers.locale.replaceLatinDigits(client.contact.cell))
        telegram.setContent(helpers.locale.replaceLatinDigits(client.contact.telegram))
    }

    return Object.assign(base, {
        refresh,
        label: 'پروفایل',
        id: 'profile',
        icon: images.ICONS.CLIENTS,
        onFocus() {
            console.log('Client Profile Tab entered')
        },
        onBlur() {
            console.log('Client Profile Tab exited')
        },
    })
}