import { Base } from "../../../../../../base/components/base"
import { Div } from "../../../../../../base/components/native/div"
import { Price } from "../../../../../shared/price"


export const TransactionTotal = (v: number) => {

    const base = Base()

    // Row
    base.cssClass({
        display: 'grid',
        gridTemplateColumns: '98px 1fr',
        gap: '14px',
        borderBottom: '1px solid black',
        backgroundColor: '#ffebf0',
        position: 'sticky',
        top: '0',
        zIndex: '4',
        padding: '5px 0',
        marginLeft: '60px',
        marginTop: '10px',
    })

    const title = Div('همه ')
    title.cssClass({
        position: 'sticky',
        fontSize: '20px',
        fontWeight: 'bold',
        textAlign: 'left',
        top: '0',
    })
    base.append(title)
    // Total
    const value = Price(v)
    value.cssClass({
        fontSize: '20px',

    })
    base.append(value)

    return base
}