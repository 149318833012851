import { createEmitter } from '../base/utils/emitter'
import { AppEventMap } from '../interfaces/app-events'
import { TBusiness } from '../interfaces/business'
import { createBusiness } from '../models/business'
import db from './db'

function all(page: number) {
    return db.all<TBusiness>('businesses')
}

async function get(remoteId: string | undefined, tempId: string | undefined = remoteId): Promise<TBusiness | null> {
    const [foundById] = await db.find<TBusiness>('businesses', { index: 'id', value: remoteId })
    if (foundById) return foundById
    return db.byId<TBusiness>('businesses', tempId)
}

async function add(data?: TBusiness): Promise<TBusiness> {
    const business = createBusiness(data)
    const added = db.save('businesses', business) as Promise<TBusiness>
    return added
}

async function update(remoteId: string | undefined, tempId: string, data: any) {
    let existing = await get(remoteId, tempId)
    if (!existing) throw new Error('business not found')
    return db.update('businesses', existing.temp_id, data)
}

function count(): Promise<number> {
    return db.count('businesses') as Promise<number>
}

async function remove(localOrServerId: string) {
    let existing = await get(localOrServerId)
    if (!existing) throw new Error('business not found')
    return db.update('businesses', existing.temp_id, { ...existing, deleted: 1 })
}

function unSynced() {
    return db.find<TBusiness>('businesses', { index: 'synced', value: 0 })
}

export default {
    all,
    add,
    get,
    update,
    count,
    remove,
    unSynced,
    ...createEmitter<AppEventMap>()
}