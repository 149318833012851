import { Div } from "../../../base/components/native/div"
import images from "../../../configs/images"
import { ActionButton } from "../action-button"
import { DangerButton } from "../danger-button"
import { baseStyle, buttonStyle } from "./index.style"

export const EditRemove = () => {
    const base = Div()
    base.cssClass(baseStyle)

    const edit = ActionButton('ویرایش', { icon: images.ICONS.EDIT, background: '#fff', color: '#222', border: '#ccc' })
    edit.cssClass(buttonStyle) // Todo: styles interfere with each other
    edit.on('click', () => base.emit('edit'))
    base.append(edit)

    const remove = DangerButton('حذف')
    remove.style(buttonStyle)

    remove.on('click', () => {
        console.log('remove clicked');
        base.emit('remove')
    })
    base.append(remove)


    return base
}