import ldb from "../base/lib/ldb";
import { XHR } from "../base/lib/xhr";

async function getSubscriptionStatus(phone: string) {
    return XHR.get('/api/reminder/users/' + phone + '/subscription/status')
}

async function createSubscriptionToken(phone: string) {
    if (!phone) return
    const formatted = phone.replace(/^0/, '98')
    return XHR.post('/api/reminder/users/' + formatted + '/subscription/token', {})
}

async function getDeliveryStatus(appointmenrId: string) {
    return XHR.get('/api/reminder/appointments/' + appointmenrId + '/delivery/status')
}

export default {
    getSubscriptionStatus,
    createSubscriptionToken,
    getDeliveryStatus
}