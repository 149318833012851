import { Div } from "../../../../base/components/native/div"
import ldb from "../../../../base/lib/ldb"
import state from "../../../../base/services/state"
import images from "../../../../configs/images"
import constatnts from "../../../../constatnts"
import helpers from "../../../../helpers"
import { TClient } from "../../../../interfaces/client"
import services from "../../../../services"
import { ActionBack } from "../../../shared/action-back"
import { ActionButton } from "../../../shared/action-button"
import { PageHeader } from "../../../shared/page-header"
import { WizardSlide } from "../../../shared/slide/slide"
import { MessagePreview } from "../../shared/message-preview"
import { baseStyle, descStyle, keyStyle } from "./send.style"

export const SetReminderSendSlide = () => {
    let t = ''
    let l = ''

    const base = WizardSlide()
    base.on('enter', enter)
    base.cssClass(baseStyle)

    const header = PageHeader('لینک عضویت')
    base.append(header)

    const desc = Div(constatnts.bot.SEND_LINK_TO_CLIENT)
    desc.cssClass(descStyle)
    base.append(desc)

    const keys = Div()
    keys.cssClass(keyStyle)
    base.append(keys)

    const message = MessagePreview()
    keys.append(message)

    const copy = ActionButton('کپی پیغام', { icon: images.ICONS.COPY, background: '#fff', color: '#222', border: '#222' })
    copy.on('click', handleCopy)
    keys.append(copy)

    const send = ActionButton('ارسال از طریق تلگرام', { icon: images.ICONS.TELEGRAM, background: '#fff', color: '#222', border: '#222' })
    send.on('click', handleSend)
    keys.append(send)

    const prev = ActionBack(helpers.strings.PREV_STEP)
    prev.on('click', () => base.emit('prev'))
    keys.append(prev)

    function handleSend() {
        if (navigator.share) {
            navigator.share({
                title: 'Share via Telegram',
                text: t,
                url: ''
            })
        }
        setTimeout(() => base.emit('done'), 400)
    }

    function handleCopy() {
        navigator.clipboard.writeText(l).then(() => {
            copy.setText('کپی شد')
        })
    }

    async function enter() {
        copy.setText('کپی پیغام')
        const { client } = state.get('new-reminder') as { client: TClient }
        const title = 'todo: add title'
        const { data: { joinToken } } = await services.reminder.createSubscriptionToken(client?.contact.cell)
        const greeting = client?.name + ' ' + 'عزیز،'
        const link = 'https://t.me/flepuserbot?start=' + joinToken
        const text = `${greeting}\n\n ${constatnts.bot.JOIN_TEXT}\n\n${link}\n\n${constatnts.general.THANKS}\n${title}`
        l = link
        t = text
        setTimeout(() => message.html(link), 150)
    }

    return base
}