import { Base } from "../../../../../../base/components/base"

export const TransactionsList = () => {

    const base = Base()
    base.style({
        padding: '0px 60px 60px 0px',
        position: 'relative',
    })


    return base
}