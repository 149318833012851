import { Wizard } from '../../../shared/slide/wizard'
import { NameSlide } from './slides/name'
import { PhoneSlide } from './slides/phone'
import { TelegramSlide } from './slides/telegram'

export const AddClientWizard = () => {

    const nameSlide = NameSlide()
    const phoneSlide = PhoneSlide()
    const telegramSlide = TelegramSlide()
    const base = Wizard([nameSlide, phoneSlide, telegramSlide])

    return base
}