import { Wizard } from '../../../shared/slide/wizard';
import { DescSlide } from './slides/desc';
import { FeesSlide } from './slides/fees';
import { TimeSlide } from './slides/time';
import { UserSlide } from './slides/user';


export const AddAppointmentFromDayWizard = () => {

    const user = UserSlide()
    const time = TimeSlide()
    const desc = DescSlide()
    const base = Wizard([user, time, desc])

    return base
}