import { Div } from "../../base/components/native/div"
import { Img } from "../../base/components/native/img"
import router, { IRouteParams } from "../../base/lib/router"
import images from "../../configs/images"
import helpers from "../../helpers"
import services from "../../services"
import { ActionBack } from "../shared/action-back"
import { ActionButton } from "../shared/action-button"
import { Page } from "../shared/page"
import { PageHeader } from "../shared/page-header"
import { editHintNote, messageNote } from "./shared/content"
import { generateMessage } from "./shared/generate-message"
import { MessagePreview } from "./shared/message-preview"

export const ReminderPage = () => {
    let appointmentId = ''
    let clientId = ''
    const base = Page()
    base.cssClass({ padding: '0 60px 0 60px' })

    const header = PageHeader('تنظیم یادآوری')
    base.append(header)

    const desc = Div(messageNote)
    desc.cssClass({ marginTop: '60px' })
    base.append(desc)

    const message = MessagePreview()
    base.append(message)

    const buttons = Div()
    buttons.cssClass({ marginTop: '30px', display: 'flex', gap: '16px', flexDirection: 'column' })
    base.append(buttons)

    const send = ActionButton('تایید نهایی')
    send.on('click', handleSend)
    buttons.append(send)

    const edit = ActionBack('ویرایش اطلاعات پیام')
    edit.on('click', handleEdit)
    buttons.append(edit)

    const editHint = Div(editHintNote)
    const editHintIcon = Img(images.ICONS.FINGER_TOP, { width: 24 })
    editHintIcon.cssClass({ marginLeft: '10px' })
    editHint.prepend(editHintIcon)
    editHint.cssClass({ display: 'none', fontStyle: 'italic', color: '#666' })
    buttons.append(editHint)

    const cancel = ActionBack(' لـغـو و برگشت')
    cancel.style({ display: 'none' })
    cancel.on('click', handleCancel)
    buttons.append(cancel)

    async function handleSend() {
        send.setLoading(true)
        // check if user is logged in, if not redirect to login
        const loggedIn = await services.auth.check()

        if (!loggedIn?.ok) {
            router.goto('/login?redirect=' + location.pathname)
            send.setLoading(false)
            return
        }

        // By default notifications are enabled
        const client = await services.clients.get(clientId)
        await services.clients.update(clientId, clientId, { ...client, notifications_enabled: 1, updated_at: new Date().toISOString(), synced: 0 })
        // todo: update the appointment with the message
        // Todo: not sure about this

        await services.sync.run()
        const telegram = client?.contact?.telegram as string
        const { data } = await services.clients.isJoinedTheBot(telegram.replace(/^0/, '98'))

        send.setLoading(false)
        if (data) {
            if (router.history.length > 1) {
                router.back()
            } else {
                router.goto('/')
            }
        } else {
            // set for all appointments of this client
            router.goto(`/clients/${clientId}/reminders/setup`)
            // show confirmation message
            // router.goto(location.pathname.replace(/\w+\/details/, 'setup'))
        }
        // save and push and sync
    }

    function handleEdit() {
        editHint.style({ display: 'flex' })
        cancel.style({ display: 'flex' })
        edit.style({ display: 'none' })
        send.style({ display: 'none' })
        const allSpans = message.el.querySelectorAll('span')
        allSpans.forEach(span => {
            span.classList.add('shake')
            span.addEventListener('click', () => {
                router.goto(location.href + '/edit/' + span.id)
            })
        })
    }

    function handleCancel() {
        editHint.style({ display: 'none' })
        cancel.style({ display: 'none' })
        edit.style({ display: 'flex' })
        send.style({ display: 'flex' })
        const allSpans = message.el.querySelectorAll('span')
        allSpans.forEach(span => {
            span.classList.remove('shake')
        })
    }

    // '/clients/:clientId/reminders/:appointmentId/setup': SetupReminderPage,
    base.on('enter', async ({ params }: IRouteParams) => { // todo: fix types
        // const messageBody = // services.reminders.getReminderMessage(params.clientId, params.appointmentId)
        const messageBody = await generateMessage(params.clientId, params.appointmentId)
        message.html(messageBody)
        appointmentId = params.appointmentId
        clientId = params.clientId
    })

    base.on('exit', ({ to }: IRouteParams) => {
        handleCancel()
        if (to === '/') { // Todo: check the design decision
            return base.style(helpers.styles.PAGE_EXIT, 0)
        }
    })

    return base
}