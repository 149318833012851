import { withRipple } from "../../../base/components/advanced/ghost/ghost"
import { Div } from "../../../base/components/native/div"
import { Img } from "../../../base/components/native/img"
import { jDateFormatter } from "../../../base/helpers/date"
import { NUMBERS_DELIMITER_REGEX } from "../../../base/helpers/regex"
import router from "../../../base/lib/router"
import images from "../../../configs/images"
import helpers from "../../../helpers"
import locale from "../../../helpers/locale"
import { TAppointment } from "../../../interfaces/appointments"
import services from "../../../services"
import { AppointmentReminder } from "../appointment-reminder"
import { arrowStyle, baseStyle, clientStyle, dateStyle, descriptionStyle, feeStyle, timeEndStyle, timeStartStyle } from "./appointment-item.style"

export type TAppointmentItemDailyOptions = {
    showDate?: boolean
    showClient?: boolean
}

export const AppointmentItem = (item: TAppointment, options: TAppointmentItemDailyOptions = {}) => {

    options = {
        showDate: true,
        showClient: true,
        ...options
    }

    const base = Div()
    base.cssClass(baseStyle)
    withRipple(base, { bg: '#eee' })

    const timeStart = Div(locale.replaceLatinDigits(item.time?.from))
    timeStart.cssClass(timeStartStyle)
    base.append(timeStart)

    const timeEnd = Div(locale.replaceLatinDigits(item.time?.to))
    timeEnd.cssClass(timeEndStyle)
    base.append(timeEnd)

    // Todo: double check
    if (options.showClient) {
        const client = Div()
        services.clients.get(item.client_id).then((c) => {
            client.text(c?.name || 'نامشخص')
        })
        client.cssClass(clientStyle)
        base.append(client)
    }

    if (options.showDate) {
        const date = Div(jDateFormatter.format(new Date(item.date)).replace(',', '').split(' ').reverse().join(' '))
        date.cssClass(dateStyle)
        base.append(date)
    }

    const description = Div(item.description)
    description.cssClass(descriptionStyle)
    base.append(description)

    if (item.fee) {
        const formattedFee = helpers.locale.replaceLatinDigits(item.fee.toString().replace(NUMBERS_DELIMITER_REGEX, ','))
        const coinIcon = Img(images.ICONS.COINS, { width: 16 })
        const title = Div(formattedFee)
        const toman = Div('تومان')
        toman.cssClass({ fontSize: '12px' })
        const fee = Div()
        fee.append(coinIcon, title, toman)
        fee.cssClass(feeStyle)
        base.append(fee)
    }

    if (new Date() < new Date(item.date)) {
        AppointmentReminder(item).then(base.append)
    }

    const arrow = Img(images.ICONS.ARROW_LEFT2, { width: 32 })
    base.el.onclick = () => {
        router.goto(`/clients/${item.client_id}/appointments/${item.id || item.temp_id}`)
    }
    arrow.cssClass(arrowStyle)

    return base
}



// const fees = Price(item.fees)
// fees.cssClass({
//     fontSize: '14px',
//     color: '#ea4c89',
//     marginTop: '5px'
// })
// if (item.fees) base.append(fees)


// const edit = Img(images.ICONS.EDIT, { width: 32 })
// edit.cssClass({
//     position: 'absolute',
//     opacity: '0.3',
//     top: 'calc(50% - 26px)',
//     left: '-10px',
// })
// ghostify(edit, { bg: '#eee' })
// base.append(edit)
// edit.el.onclick = (e) => {
//     e.stopPropagation()
//     router.goto(`/clients/${item.clientId}/appointments/${item.id}`)
// }