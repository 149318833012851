import { A } from '../../../base/components/native/a'
import { Div } from '../../../base/components/native/div'
import state from '../../../base/services/state'
import images from '../../../configs/images'
import helpers from '../../../helpers'
import strings from '../../../helpers/strings'
import { ActionBack } from '../../shared/action-back'
import { ActionButton } from '../../shared/action-button'
import { DInput } from '../../shared/d-input'
import { Page } from '../../shared/page'
import { PageHeader } from '../../shared/page-header'
import { WizardSlide } from '../../shared/slide/slide'

export const BotSlide = () => {
    // Todo : put user on hold to receive the code
    const base = WizardSlide()
    base.cssClass({
        padding: '15px 60px',
    })
    base.on('enter', () => { link.setValue('https://t.me/flepuserbot?start=' + state.get('login-request').joinToken + '-OTP') })

    const title = PageHeader('عضویت در بات تلگرامی')
    base.append(title)

    const desc = Div(`
        کاربر گرامی، رمز یک‌بار مصرف و سایر اطلاع‌رسانی‌ها از طریق بات تلگرامی فلپ ارسال می‌شود.
        <br/>
        لطفا روی لینک زیر کلیک کرده تا عضو  شوید.
        `)
    desc.cssClass({
        margin: '60px 0 20px',
        // display: 'none'
    })
    base.append(desc)

    const link = A()
    link.cssClass({
        display: 'block',
        margin: '10px auto',
        textAlign: 'center',
        fontSize: '18px',
        color: '#ea4c89',
        backgroundColor: '#ea4c8912',
        padding: '10px',
        borderRadius: '20px',
        textDecoration: 'none',
    })
    link.el.onclick = () => {
        base.emit('next')
    }
    base.append(link)


    // const copy = ActionButton('کپی پیغام', { icon: images.ICONS.COPY, background: '#fff', color: '#222', border: '#222' })
    // copy.cssClass({
    //     marginTop: '20px',
    // })
    // base.append(copy)
    // copy.on('click', () => {
    //     navigator.clipboard.writeText(link.getHref()).then(() => {
    //         copy.setText('کپی شد')
    //     }).catch(err => {
    //         console.error('Could not copy text: ', err);
    //     })
    // })

    const prev = ActionBack(strings.PREV_STEP)
    prev.on('click', () => base.emit('prev'))
    prev.cssClass({
        marginTop: '20px',
    })
    base.append(prev)

    return base
}