import { withRipple } from "../../../base/components/advanced/ghost/ghost"
import { Div } from "../../../base/components/native/div"
import { CENTER } from "../../../base/helpers/style"
import helpers from "../../../helpers"
import { TAppointment } from "../../../interfaces/appointments"
import * as jalaaliJs from "../../../lib/jalaali-js"
import services from "../../../services"
import { emitter } from "../../../services/emitter"
import { AppointmentItem } from "../days/appointment-item"
import { Cell } from "./cell"
import { DayCell } from "./day-cell"
import { MonthTitle } from "./month-title"

export const Month = ({ jm, jy }: { jm: number, jy: number }) => {

    const base = Div()
    base.cssClass({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        overflowY: 'auto',
        padding: 'calc(env(safe-area-inset-top)) 0 120px 0',
        direction: 'rtl',

    })

    const title = MonthTitle({ jm, jy })
    base.append(title)

    const monthContainer = Div()
    monthContainer.cssClass({
        display: 'grid',
        padding: '60px 50px 0 30px',
        gap: '5px',
        gridTemplateColumns: 'repeat(7, 1fr)',
        height: window.innerWidth + 'px',
    })
    base.append(monthContainer)

    const jMonthLength = jalaaliJs.jalaaliMonthLength(jy, jm)
    const { gy, gm, gd } = jalaaliJs.toGregorian(jy, jm, 1);
    // const start = new Date(gy, gm - 1, gd)
    // const end = new Date(gy, gm - 1, jMonthLength + gd);

    ['ش', 'ی', 'د', 'س', 'چ', 'پ', 'ج'].forEach(day => {
        const cell = Cell(day)
        monthContainer.append(cell)
        if (['پ', 'ج'].includes(day)) cell.cssClass({ opacity: '.5' })
    })

    const { friday } = jalaaliJs.jalaaliWeek(jy, jm, 1)

    const emptyCells = 8 - friday.jd
    for (let i = 1; i < emptyCells; i++) {
        const cell = Cell('')
        monthContainer.append(cell)
    }


    /* !!! TEMP */
    // const map = new Map()
    // services.appointments.getAll(0).then((appointments: TAppointment[]) => {
    // console.log(appointments)

    for (let i = 1; i <= jMonthLength; i++) {
        const dayCell = DayCell({ jm, jy, jd: i })
        monthContainer.append(dayCell)
    }

    const tip = Div('برای مشاهده یا ثبت نوبت روی روز دلخواه کلیک کنید')
    tip.cssClass({
        position: 'relative',
        padding: '15px',
        textAlign: 'center',
        borderRadius: '15px',
        color: 'gray',
        backgroundColor: '#f1f1f1',
        zIndex: '100',
        width: '200px',
        margin: '70px auto',
        '&:after': {
            // triangle with white background and eee border
            content: '""',
            position: 'absolute',
            top: '-24px',
            left: 'calc(50% - 5px)',
            width: '0px',
            height: '0',
            border: '10px solid transparent',
            borderBottom: '10px solid #f1f1f1',
        }

    })
    base.append(tip)
    // appointments.forEach(appointment => {
    //     const { date } = appointment
    //     const { jm, jy, jd } = jalaaliJs.toJalaali(date)
    //     const key = `${jm}-${jy}-${jd}`
    //     if (!map.has(key)) map.set(key, [])
    //     map.get(key).push(appointment)

    // })

    // for (let i = 1; i <= jMonthLength; i++) {
    //     const dayCell = DayCell({ jm, jy, jd: i, eventsNum: map.get(`${jm}-${jy}-${i}`)?.length || 0 })

    //     monthContainer.append(dayCell)
    // }

    // })



    // const list = Div()
    // list.cssClass({
    //     marginTop: '40px',
    //     marginRight: '60px',
    //     borderRight: '3px dotted #ddd',
    // })
    // base.append(list)

    // دشت امروز
    // const list = Div()
    // list.cssClass({
    //     display: 'flex',
    //     flexDirection: 'column',
    //     width: '100%',
    //     height: '100%',
    //     overflow: 'hidden',
    //     overflowY: 'auto',
    //     padding: '60px 0 120px 0'
    // })
    // base.append(list)

    // for (let i = 0; i < 24; i++) {
    //     const div = Div(i + '')
    //     div.cssClass({
    //         width: '100%',
    //         height: '40px',
    //         paddingTop: '10px',
    //         color: '#bbb',
    //         paddingRight: '22px',
    //         borderBottom: '1px solid #eee',
    //         flexShrink: '0',
    //     })
    //     list.append(div)
    // }
    // list.el.scrollTop = 480

    return base
}