import app from '../../app'
import { IBaseComponent } from '../../base/components/base'
import { Div } from '../../base/components/native/div'
import { CS } from '../../base/utils/styler'
import { Body } from '../shared/body'
import { Page } from '../shared/page'

export const TestPage = () => {
    const base = Page()
    const body = Body()
    body.cssClass({ padding: '40px', direction: 'ltr' })
    base.append(body)
    body.append(MatchFinder())
    return base
}

function TransactionItem(item: Transaction) {
    let selected = false
    const base = Div()
    base.cssClass(transactionItemStyle)
    const ref = Div(item.reference)
    const amount = Div('€ ' + item.amount)
    amount.cssClass({ color: item.amount > 0 ? 'green' : 'red' })
    base.append(ref, amount)
    base.el.onclick = () => {
        selected = !selected
        base.style({ backgroundColor: selected ? '#f0f0f0' : 'white' })
        base.emit('change', selected)
    }

    return Object.assign(base, {
        getId() {
            return item.id
        }
    })
}

function List(title: string, items: Transaction[] = []) {
    const base = Div()

    const label = Div(title)
    label.cssClass(listLabelStyle)
    base.append(label)

    let selectedItems: Transaction[] = []
    let ch = []
    return Object.assign(base, {
        setValues: (items: Transaction[]) => {
            base.empty()
            items.forEach(item => {
                const tx = TransactionItem(item)
                ch.push(tx)
                tx.on('change', (selected: boolean) => {
                    if (selected) {
                        selectedItems.push(item)
                    } else {
                        selectedItems = selectedItems.filter(i => i.id !== item.id)
                    }
                    base.emit('change', selectedItems)
                })
                base.append(tx)
            })
        },
        removeItems: (items: Transaction[]) => {
            selectedItems = []
            ch.forEach((child: IBaseComponent<any>) => {
                if (items.some(i => i.id === child?.getId())) {
                    child.remove()
                }
            })
        }
    })
}

function MatchFinder() {
    const base = Div()
    base.cssClass(matchFinderStyle)

    const title = Div('Match Finder')
    title.cssClass(titleStyle)
    base.append(title)

    const lists = Div()
    lists.cssClass(listsStyle)
    base.append(lists)



    const leftList = List('Left List')
    leftList.on('change', (items: Transaction[]) => leftSelectedItems = items)
    lists.append(leftList)

    const rightList = List('Right List')
    rightList.on('change', (items: Transaction[]) => rightSelectedItems = items)
    lists.append(rightList)

    const loading = Div('Loading...')
    lists.append(loading)
    getProcessedTransactions().then(({ left, right }) => {
        loading.remove()
        leftList.setValues(left)
        rightList.setValues(right)
    })

    const apply = Div('Apply')
    apply.el.onclick = match
    apply.cssClass(buttonStyle)
    base.append(apply)

    let leftSelectedItems: Transaction[] = []
    let rightSelectedItems: Transaction[] = []
    function match() {
        const sumLeft = leftSelectedItems.reduce((acc, tx) => acc + tx.amount, 0)
        const sumRight = rightSelectedItems.reduce((acc, tx) => acc + tx.amount, 0)
        if (sumLeft === sumRight) {
            console.log('Matched', leftSelectedItems, rightSelectedItems);

            leftList.removeItems(leftSelectedItems)
            leftSelectedItems = []
            rightList.removeItems(rightSelectedItems)
            rightSelectedItems = []
        }
    }

    return base
}

async function getProcessedTransactions() {
    const transactions = await getTransactions() as Transaction[]
    const { left, right } = transactions.reduce((acc: ProcessedTransactions, tx) => {
        acc[tx.list].push(tx)
        return acc
    }, { left: [], right: [] })

    return { left, right }
}

function getTransactions() {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve([
                { id: 'tx0', amount: 15, currency: 'EUR', reference: 'Friday dinner', date: '2024-09-15', list: 'left' },
                { id: 'tx1', amount: 1, currency: 'EUR', reference: 'Bank fee', date: '2024-08-01', list: 'right' },
                { id: 'tx2', amount: 14, currency: 'EUR', reference: 'Grocery store', date: '2024-08-29', list: 'right' },
                { id: 'tx3', amount: -11, currency: 'EUR', reference: 'Account Top-Up', date: '2024-09-05', list: 'right' },
                { id: 'tx4', amount: 2, currency: 'EUR', reference: 'Theater tickets', date: '2024-09-05', list: 'left' },
                { id: 'tx5', amount: -4, currency: 'EUR', reference: 'Public transport', date: '2024-08-03', list: 'left' },
                { id: 'tx6', amount: -8, currency: 'EUR', reference: 'Music subscription', date: '2024-09-15', list: 'right' },
                { id: 'tx7', amount: -5, currency: 'EUR', reference: 'Plumber services', date: '2024-08-03', list: 'left' },
                { id: 'tx8', amount: 3, currency: 'EUR', reference: 'Gas station', date: '2024-07-21', list: 'left' },
                { id: 'tx9', amount: -1, currency: 'EUR', reference: 'Office snacks', date: '2024-09-15', list: 'left' },
            ]);
        }, 1000);
    });
}

interface Transaction {
    id: string
    amount: number
    currency: string
    reference: string
    date: string
    list: 'left' | 'right'
}

interface ProcessedTransactions {
    left: Transaction[]
    right: Transaction[]
}

/* STYLES */

const transactionItemStyle: CS = {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    padding: '5px 10px',
    borderRadius: '8px',
    marginTop: '7px',
    cursor: 'pointer',
    width: 'fit-content',
    '&:hover': {
        opacity: '.8'
    }
}

const listLabelStyle: CS = {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px'
}

const matchFinderStyle: CS = {
    margin: '20px'
}

const titleStyle: CS = {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px'
}

const listsStyle: CS = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px'
}

const buttonStyle: CS = {
    textAlign: 'center',
    userSelect: 'none',
    padding: '10px',
    marginTop: '20px',
    cursor: 'pointer',
    backgroundColor: '#f0f0f0',
    borderRadius: '5px',
    '&:hover': {
        backgroundColor: '#e0e0e0'
    },
    '&:active': {
        backgroundColor: '#d0d0d0'
    }
}